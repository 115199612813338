import { useMemo, forwardRef } from 'react';
import { NumericFormat as Format } from 'react-number-format';
import TextField from '@mui/material/TextField';
import withController from './withController';
import { transformLabel } from './utils';

const NumericFormat = forwardRef((props, ref) => {
  const { onChange, value, ...other } = props;

  return (
    <Format
      {...other}
      value={value ?? ''}
      getInputRef={ref}
      autoComplete="off"
      isAllowed={(values) => {
        const { floatValue } = values;
        return !floatValue || floatValue <= (other?.max ?? Number.MAX_SAFE_INTEGER);
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: other.formatted ? values.formattedValue : values.floatValue ?? null
          }
        });
      }}
      allowNegative={false}
      decimalScale={2}
      thousandSeparator={false}
    />
  );
});

const RHFNumericField = ({ name, invalid, error, ...props }) => {
  const label = useMemo(() => props.label ?? transformLabel(name) ?? '', [props.label, name]);
  const placeholder = useMemo(() => {
    if (props.placeholder === true) {
      return `Enter ${label}`;
    }
    return props.placeholder;
  }, [props.placeholder, label]);

  return (
    <TextField
      {...props}
      label={label}
      placeholder={placeholder}
      error={invalid}
      helperText={error?.message}
      InputProps={{
        ...props.InputProps,
        inputComponent: NumericFormat
      }}
    />
  );
};

export default withController(RHFNumericField);
