import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { MenuItem, TextField, Tooltip, InputAdornment, IconButton, Checkbox, ListItemText, Box, Button, Grid } from '@mui/material';
import CurrencyInput from 'ui-component/currencyInput';
import NumericInput from 'ui-component/numericInput';
import PercentageInput from 'ui-component/percentageInput';
import { DatePicker } from '@mui/x-date-pickers';
import InfoIcon from '@mui/icons-material/Info';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as calculations from '../../utils/calculations';
import * as activations from '../../utils/activations';
import { useSelector } from 'react-redux';
import { yupDate } from 'utils/date-validate';
import ListMap from 'ui-component/listMap';
import { Close } from '@mui/icons-material';
dayjs.extend(utc);

export const FieldTypes = {
  Currency: 'currency',
  Numeric: 'numeric',
  Percentage: 'percentage',
  Date: 'date',
  MonthAndYear: 'month-and-year',
  Select: 'select',
  Text: 'text',
  MultiSelect: 'multi-select',
  ListMap: 'list-map'
};

export const NULLABLE_VALUE = 'NULL';

export const DateFormats = {
  [FieldTypes.Date]: 'MM/DD/YYYY',
  [FieldTypes.MonthAndYear]: 'MM/YYYY'
};

function transformLabel(s) {
  if (typeof s != 'string') return;
  if (s.includes('.')) {
    s = s.split('.').at(-1);
  }
  s = s.replace(/_/g, ' ');

  return s
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const fieldSchema = ({ type, required, label, options }) => {
  let yupSchema, typeError, oneOfError;

  switch (type) {
    case FieldTypes.Date:
    case FieldTypes.MonthAndYear:
      yupSchema = yupDate(DateFormats[type]);
      break;
    case FieldTypes.Select:
      yupSchema = Yup.mixed().transform((v) => (!v ? undefined : v));
      oneOfError = 'Invalid option selected.';
      break;
    case FieldTypes.MultiSelect:
    case FieldTypes.ListMap:
      yupSchema = Yup.array();
      break;
    case FieldTypes.Currency:
      yupSchema = Yup.number();
      typeError = 'Invalid amount entered.';
      break;
    case FieldTypes.Numeric:
      yupSchema = Yup.number();
      typeError = 'Invalid value entered.';
      break;
    case FieldTypes.Percentage:
      yupSchema = Yup.number();
      typeError = 'Invalid percentage entered.';
      break;
    default:
      yupSchema = Yup.string();
      break;
  }

  if (required) {
    yupSchema = yupSchema.required(`${label} is required.`);
  } else {
    yupSchema = yupSchema.nullable();
  }

  if (typeError) {
    yupSchema = yupSchema.typeError(typeError);
  }

  if (oneOfError && Array.isArray(options)) {
    options = ['', ...options];
    yupSchema = yupSchema.oneOf(
      options.map((option) => option?.value || option),
      oneOfError
    );
  }

  return yupSchema;
};

const validate =
  ({ objectValues, ...props }) =>
  async (value) => {
    try {
      let yupSchema = fieldSchema({
        type: props.type,
        label: props.label,
        required: props.required,
        options: props.options
      });
      if (props.schema) {
        yupSchema = props.schema(yupSchema, objectValues ?? {}) ?? yupSchema;
      }
      await yupSchema.validate(value);
      return true;
    } catch (err) {
      console.log(err, 'err');
      return err?.message;
    }
  };

const CustomActionBar = memo(({ onChange, getClients }) => {
  const retirementDate = useMemo(() => {
    const clients = getClients();
    if (!clients.length) return null;
    let client = clients[0];
    if (client.joint === 1 && clients.length > 1) {
      client = clients.reduce((maxClient, client) => {
        return client.retirement_age > (maxClient?.retirement_age || 0) ? client : maxClient;
      }, client);
    }
    return client?.planning_horizon ? dayjs(client?.date_of_birth, 'YYYY-MM-DD').add(client?.retirement_age, 'year') : null;
  }, [getClients]);

  const planningHorizon = useMemo(() => {
    const clients = getClients();
    if (!clients.length) return null;
    let client = clients[0];
    if (client.joint === 1 && clients.length > 1) {
      client = clients.reduce((maxClient, client) => {
        return client.planning_horizon > (maxClient?.planning_horizon || 0) ? client : maxClient;
      }, client);
    }
    return client?.date_of_birth ? dayjs(client?.date_of_birth, 'YYYY-MM-DD').add(client?.planning_horizon, 'year') : null;
  }, [getClients]);

  return (
    <Box sx={{ gridColumn: 2, gridRow: 3, display: 'flex', gap: 1, p: 1.5, pt: 0 }}>
      <Button disabled={retirementDate === null} variant="contained" onClick={() => onChange(retirementDate)}>
        Retirement Date
      </Button>
      <Button disabled={planningHorizon === null} variant="contained" onClick={() => onChange(planningHorizon)}>
        Planning Horizon
      </Button>
    </Box>
  );
});

const FormField = ({
  id,
  field,
  control,
  invalid,
  error,
  label,
  type,
  required,
  placeholder,
  options,
  onChange,
  calculate,
  dependencies,
  info,
  financialPlan,
  optionKey,
  ...other
}) => {
  // Clients
  const getClients = useCallback(() => {
    const userClients = structuredClone(financialPlan?.goals);
    return userClients.sort((a) => (a.user_id === +field.name.split('.')?.at(0) ? -1 : 1));
  }, [financialPlan?.goals, field.name]);

  // Dependencies
  const fields = useWatch({
    name: dependencies ?? [],
    control,
    disabled: !dependencies
  });

  // Date-specific Functions
  const toDateFormat = useCallback((date) => (date ? dayjs(date)?.utc(true)?.format(DateFormats[type]) : null), [type]);

  const fromDateFormat = useCallback((date) => (date ? dayjs(date, DateFormats[type]) : null), [type]);

  const maxPlanningHorizonDate = useMemo(() => {
    const clients = getClients();
    let [client] = clients;
    if (clients.length > 1) {
      client = clients.reduce((maxClient, client) => {
        return client.planning_horizon > (maxClient?.planning_horizon || 0) ? client : maxClient;
      }, null);
    }
    return client?.planning_horizon ? dayjs(clients?.[0]?.date_of_birth, 'YYYY-MM-DD').add(client?.planning_horizon, 'year') : null;
    // eslint-disable-next-line
  }, []);

  const handleChange = useCallback(
    (event) => {
      if (onChange) {
        onChange(event);
      } else {
        field.onChange(event);
      }
    },
    [onChange, field]
  );

  // Calculation
  const calculatedValue = useMemo(() => {
    if (calculate) {
      let value = !dependencies
        ? calculate(getClients(), financialPlan?.dropdowns ?? {})
        : fields.every((f) => f !== null && f !== undefined && ((typeof f === 'string' && f !== 'Invalid Date') || typeof f !== 'string'))
          ? calculate(...fields, getClients(), financialPlan?.dropdowns ?? {})
          : null;
      return [FieldTypes.Date, FieldTypes.MonthAndYear].includes(type) && !value?.limit && value !== NULLABLE_VALUE
        ? toDateFormat(value)
        : value;
    }
    return null;
    // eslint-disable-next-line
  }, [fields]);

  // Update Field
  useEffect(() => {
    if (calculate && !calculatedValue?.limit) {
      handleChange({
        target: {
          id: field.name,
          value: calculatedValue
        }
      });
    }
    // eslint-disable-next-line
  }, [calculatedValue]);

  useEffect(() => {
    if (![FieldTypes.Select, FieldTypes.MultiSelect].includes(type)) return;
    let optionValues = options?.map((opt) => opt?.value ?? opt),
      filteredValues;

    // Reset to empty if the option is not found in the options array
    if (type === FieldTypes.Select && field.value !== '') {
      !optionValues.includes(field.value) && setTimeout(() => handleChange({ target: { id: field.name, value: '' } }));
    } else if (type === FieldTypes.MultiSelect && Array.isArray(field.value) && field.value.length) {
      filteredValues = field.value.filter((item) => optionValues.includes(item));
      filteredValues.length !== field.value.length &&
        setTimeout(() => handleChange({ target: { id: field.name, value: filteredValues ?? [] } }));
    }

    if (!options?.length) return;
    // Handle dynamic option relationships
    if (id && optionKey) {
      filteredValues = options.flatMap((option) => (option.data?.[optionKey] === id ? [option.value] : []));
      // filteredValues.length &&
      setTimeout(() =>
        handleChange({
          target: {
            id: field.name,
            value: type === FieldTypes.MultiSelect ? (filteredValues.length ? filteredValues : []) : filteredValues[0] ?? ''
          }
        })
      );
    }
    // eslint-disable-next-line
  }, [options]);

  // Common Props
  const commonProps = {
    ...field,
    id: field.name,
    label,
    placeholder: placeholder ?? `Enter ${label}`,
    fullWidth: true,
    error: invalid,
    helperText: error?.message,
    required: !calculate && required,
    variant: 'outlined',
    onChange: handleChange,
    ...([FieldTypes.Select, FieldTypes.MultiSelect].includes(type) && {
      sx: {
        '& .MuiInputBase-root': { padding: 0, pr: 3 },
        ...(optionKey && { '& .MuiSelect-icon': { display: 'none' } })
      }
    }),
    ...(info && {
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={info}>
              <IconButton disableRipple>
                <InfoIcon sx={{ borderRadius: '50%', background: 'white' }} fontSize="medium" color="primary" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        )
      },
      sx: {
        '& .MuiInputBase-root': { padding: 0 },
        '& .MuiSelect-icon': { right: '32px' }
      }
    }),
    ...(calculate && { value: calculatedValue?.limit ? null : calculatedValue, disabled: calculatedValue?.limit ? false : true }),
    ...(optionKey && { disabled: true })
  };

  const handleDateChange = useCallback(
    (date) => {
      const event = { target: { id: field.name, value: toDateFormat(date) ?? null } };
      field.onChange(event);
    },
    [field, toDateFormat]
  );

  switch (type) {
    case FieldTypes.Date: {
      const { InputProps, ...rest } = commonProps;
      return (
        <DatePicker
          {...rest}
          format={DateFormats[type]}
          slotProps={{
            textField: {
              required: commonProps.required,
              error: commonProps.error,
              helperText: commonProps.helperText,
              InputProps,
              inputRef: rest.inputRef
            },
            actionBar: {
              onChange: handleDateChange,
              getClients
            }
          }}
          slots={{
            actionBar: CustomActionBar
          }}
          maxDate={maxPlanningHorizonDate}
          {...(calculatedValue?.limit && {
            minDate: calculatedValue?.limit?.min ? fromDateFormat(calculatedValue?.limit?.min) : null,
            maxDate: calculatedValue?.limit?.max ? fromDateFormat(calculatedValue?.limit?.max) : null
          })}
          value={rest.value === NULLABLE_VALUE ? null : fromDateFormat(rest.value ?? field.value)}
          onChange={handleDateChange}
          sx={{ width: '100%' }}
          {...other}
        />
      );
    }
    case FieldTypes.MonthAndYear: {
      const { InputProps, ...rest } = commonProps;
      return (
        <DatePicker
          {...rest}
          format={DateFormats[type]}
          slotProps={{
            textField: {
              required: commonProps.required,
              error: commonProps.error,
              helperText: commonProps.helperText,
              InputProps,
              inputRef: rest.inputRef
            },
            actionBar: {
              onChange: handleDateChange,
              getClients
            }
          }}
          slots={{
            actionBar: CustomActionBar
          }}
          maxDate={maxPlanningHorizonDate}
          {...(calculatedValue?.limit && {
            minDate: calculatedValue?.limit?.min ? fromDateFormat(calculatedValue?.limit?.min) : null,
            maxDate: calculatedValue?.limit?.max ? fromDateFormat(calculatedValue?.limit?.max) : null
          })}
          value={rest.value === NULLABLE_VALUE ? null : fromDateFormat(rest.value ?? field.value)}
          onChange={handleDateChange}
          sx={{
            width: '100%'
          }}
          views={['month', 'year']}
          {...other}
        />
      );
    }
    case FieldTypes.Select:
      return (
        <TextField
          {...commonProps}
          value={commonProps.value ?? ''}
          select
          {...other}
          InputProps={{
            ...commonProps?.InputProps,
            ...other?.InputProps,
            endAdornment:
              !commonProps.required && !commonProps.disabled && commonProps.value && commonProps.value !== '' ? (
                <InputAdornment sx={{ marginRight: '5px' }} position="end">
                  <IconButton
                    onClick={() => {
                      commonProps.onChange({
                        target: {
                          id: commonProps.name,
                          value: ''
                        }
                      });
                    }}
                  >
                    <Close width={10} height={10} />
                  </IconButton>
                </InputAdornment>
              ) : (
                commonProps?.InputProps?.endAdornment
              )
          }}
        >
          {options?.length > 0 ? (
            React.Children.toArray(options?.map((option) => <MenuItem value={option?.value ?? option}>{option?.label ?? option}</MenuItem>))
          ) : (
            <MenuItem value="" disabled>
              No options available
            </MenuItem>
          )}
        </TextField>
      );
    case FieldTypes.ListMap:
      return <ListMap {...commonProps} options={options} {...other} />;
    case FieldTypes.MultiSelect:
      return (
        <TextField
          {...commonProps}
          value={Array.isArray(commonProps.value) ? commonProps.value : []}
          select
          SelectProps={{
            multiple: true,
            renderValue: (selected) => selected.map((item) => options.find((option) => option.value === item)?.label ?? item).join(', ')
          }}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            const selectedValues = (typeof value === 'string' ? value.split(',') : value).filter(
              (item) => options.findIndex((option) => option.value === item) > -1
            );
            // Handle special case for Insurance Costs
            if (commonProps.name.endsWith('.insurance_costs')) {
              const added = selectedValues.find((item) => !commonProps.value.includes(item));
              const removed = commonProps.value.find((item) => !selectedValues.includes(item));
              if (added && [138, 139].includes(added) && !selectedValues.includes(140)) {
                selectedValues.push(140);
              } else if (removed && [138, 139].includes(removed) && selectedValues.includes(140) && selectedValues.length == 1) {
                selectedValues.splice(selectedValues.indexOf(140), 1);
              }
            }

            handleChange({
              ...event,
              target: {
                ...event.target,
                value: selectedValues
              }
            });
          }}
          {...other}
        >
          {React.Children.toArray(
            options?.map((option) => {
              return (
                <MenuItem key={option?.value ?? option} value={option?.value}>
                  <Checkbox checked={Array.isArray(commonProps.value) && commonProps.value?.indexOf(option?.value ?? option) > -1} />
                  <ListItemText primary={option?.label} />
                </MenuItem>
              );
            })
          )}
        </TextField>
      );
    case FieldTypes.Currency:
      return <CurrencyInput {...commonProps} {...other} />;
    case FieldTypes.Numeric:
      return <NumericInput {...commonProps} {...other} />;
    case FieldTypes.Percentage:
      return <PercentageInput {...commonProps} {...other} />;
    default:
      return <TextField {...commonProps} {...other} />;
  }
};

export function RHFFormField({
  schema,
  objectValues,
  calculate,
  activate,
  dynamic,
  keep,
  options: fieldOptions,
  optionKey,
  gridProps,
  ...props
}) {
  const { control, setValue, unregister, formState } = useFormContext();
  const transformedLabel = useMemo(() => props.label ?? transformLabel(props.name) ?? '', [props.label, props.name]);
  const { initials, name } = useMemo(() => {
    const nameArray = props.name.split('.');
    const name = nameArray.pop();
    const initials = nameArray.join('.') + '.';
    return { initials, name };
  }, [props.name]);

  const dependencies = useMemo(() => props.dependencies?.map((field) => initials + field), [props.dependencies, initials]);

  // Options
  const financialPlan = useSelector((state) => state.financialPlan);
  const stateOptions = useSelector((state) => state.options.get_options);
  const options = useMemo(() => {
    let options = fieldOptions;
    if (typeof fieldOptions === 'string') {
      if (dynamic) {
        return financialPlan?.dropdowns?.[fieldOptions] ?? [];
      } else {
        return stateOptions?.[fieldOptions] ?? [];
      }
    }
    return options;
    // eslint-disable-next-line
  }, []);

  // Conditional Visibility
  const [active, setActive] = useState(!activate ? true : activations[activate]?.(objectValues));
  const [unregisteredValue, setUnregisteredValue] = useState(null);

  useEffect(() => {
    if (activate) {
      const isActive = activations[activate]?.(objectValues);
      if (isActive !== active) {
        setActive(isActive);
        if (!isActive) {
          !keep && unregister(props.name);
          setUnregisteredValue(objectValues[name]);
        } else {
          (unregisteredValue || objectValues[name]) &&
            setTimeout(() => {
              setValue(props.name, unregisteredValue ?? objectValues[name], { shouldValidate: formState.submitCount > 0 });
            });
        }
      }
    }
    // eslint-disable-next-line
  }, [objectValues]);

  if (!active) {
    return;
  }
  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={
        props.type === FieldTypes.Select
          ? ''
          : [FieldTypes.MultiSelect, FieldTypes.ListMap].includes(props.type)
            ? []
            : props.type === FieldTypes.Date
              ? null
              : undefined
      }
      rules={{
        validate: validate({
          label: transformedLabel,
          schema,
          objectValues,
          ...props,
          options,
          required: !optionKey && !calculate && props.required
        }),
        ...(!calculate && dependencies && { deps: dependencies })
      }}
      render={({ field: { ref: inputRef, ...field }, fieldState: { invalid, error } }) => (
        <Grid item xs={12} md={6} {...gridProps}>
          <FormField
            {...{
              field,
              inputRef,
              control,
              invalid,
              error,
              label: transformedLabel,
              options,
              financialPlan,
              optionKey,
              ...(objectValues && { id: objectValues.id }),
              ...props,
              ...(calculate && { calculate: calculations[calculate] }),
              dependencies
            }}
          />
        </Grid>
      )}
    />
  );
}
