import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';

const CustomNumericFormat = React.forwardRef((props, ref) => {
  const { onChange, value, ...other } = props;

  return (
    <NumericFormat
      {...other}
      value={value ?? ''}
      getInputRef={ref}
      autoComplete="off"
      isAllowed={(values) => {
        const { floatValue } = values;
        return !floatValue || floatValue <= (other?.max ?? Number.MAX_SAFE_INTEGER);
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue ?? null
          }
        });
      }}
      allowNegative={false}
      decimalScale={2}
      thousandSeparator
      prefix="$"
    />
  );
});

export default function CurrencyInput({ ...props }) {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: CustomNumericFormat
      }}
    />
  );
}
