import { UPDATE_AUTH_USER, LOGIN, GET_TENANT } from './constant';
const initialState = {
  tenant: null,
  login: ''
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login: action.payload
      };
    case UPDATE_AUTH_USER:
      return {
        ...state,
        login: {
          ...state.login,
          user: {
            ...state.login?.user,
            ...action.payload
          }
        }
      };
    case GET_TENANT: {
      if (state.tenant?.status === 0 && action.payload?.status === 1) {
        window.location.href = '/login';
      }
      return {
        ...state,
        tenant: action.payload
      };
    }
    default:
      return state;
  }
};

export default loginReducer;
