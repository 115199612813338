import { Suspense } from 'react';

// project imports
import Loader from './Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable =
  (Component, { hasFallback, skeleton } = { hasFallback: true }) =>
  (props) => (
    <Suspense fallback={hasFallback && (skeleton ?? <Loader />)}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
