import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import { useFormContext } from 'react-hook-form';

const PDFUpload = ({ name, ...props }) => {
  const { setError, clearErrors } = useFormContext();
  const fileInputRef = React.useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== 'application/pdf') {
        setError(name, { message: 'Only PDF files are allowed' });
        return;
      }
      if (selectedFile.size > 5 * 1024 * 1024) {
        setError(name, { message: 'File size should not exceed 2 MB' });
        return;
      }
      props.onChange(selectedFile);
      clearErrors(name);
    } else {
      props.onChange(null);
    }
  };

  const handleRemoveFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onChange(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <input
        accept="application/pdf"
        style={{ display: 'none' }}
        id="upload-pdf"
        type="file"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <label htmlFor="upload-pdf">
        <Box sx={{ position: 'relative', display: 'inline-block' }}>
          <IconButton
            color="primary"
            aria-label="upload pdf"
            component="span"
            sx={{
              border: '1px dashed #000',
              borderRadius: '0px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              pr: 2
            }}
            title="Upload PDF"
          >
            <UploadFileIcon sx={{ fontSize: 60 }} />
            <Typography
              variant="body1"
              sx={{
                color: '#000',
                maxWidth: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {props.value ? props.value.name : 'Upload File'}
            </Typography>
          </IconButton>
          {props.value && (
            <IconButton
              color="secondary"
              aria-label="remove pdf"
              component="span"
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.8)'
                }
              }}
              onClick={handleRemoveFile}
              title="Remove PDF"
            >
              <CloseIcon sx={{ fontSize: 20, color: 'white' }} />
            </IconButton>
          )}
        </Box>
      </label>
      {props.invalid && <p style={{ color: 'red', mt: 2 }}>{props.error.message}</p>}
    </>
  );
};

export default PDFUpload;
