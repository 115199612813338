import { useMemo } from 'react';
import withController from './withController';
import { transformLabel } from './utils';
import { MuiPhone } from 'ui-component/phoneInput';

const RHFPhoneField = ({ name, invalid, error, ...props }) => {
  const label = useMemo(() => props.label ?? transformLabel(name) ?? '', [props.label, name]);
  const placeholder = useMemo(() => {
    if (props.placeholder === true) {
      return `Enter ${label}`;
    }
    return props.placeholder;
  }, [props.placeholder, label]);

  return (
    <MuiPhone
      {...props}
      value={props.value ?? ''}
      label={label}
      placeholder={placeholder}
      error={invalid}
      helperText={error?.message}
      fullWidth
    />
  );
};

export default withController(RHFPhoneField);
