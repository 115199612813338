import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //
const NavCollapse = ({ menu, level }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = () => {
    setSelected(menu.id);
    if (menu?.id !== 'authentication') {
      menu.childOpen !== false && navigate(!menu.childOpen ? menu.url : menu.children[0].url);
      setOpen(!open || selected !== menu.id); // Toggle the 'open' state if the menu is selected or if it's not open
    }
  };

  const { pathname } = useLocation();
  const checkOpenForParent = (child, id) => {
    child.forEach((item) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  // menu collapse for sub-levels
  useEffect(() => {
    const currentIndex = document.location.pathname.includes(menu.url);
    if (document.location.pathname === menu.url || currentIndex) {
      setSelected(menu.id);
      if (!selected && document.location.pathname !== menu.url && currentIndex) {
        setOpen(true);
      }
    } else if (!selected) {
      setOpen(false);
      if (menu.children) {
        menu.children.forEach((item) => {
          if (item.children?.length) {
            checkOpenForParent(item.children, menu.id);
          }
          if (item.url === pathname) {
            setSelected(menu.id);
            setOpen(true);
          }
        });
      }
    } else {
      setSelected(null);
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children, menu]);
  // menu collapse & item
  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon width={30} height={30} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  return (
    <>
      <ListItemButton
        sx={{
          mb: level > 1 ? 0 : 0.5,
          alignItems: 'flex-start',
          backgroundColor: level > 1 ? 'transparent' : 'inherit',
          py: level > 1 ? 1 : 1.25,
          px: 2.5
        }}
        selected={selected === menu.id}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 42 }}>{menuIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
              {menu.title.replace(/^\./, '')}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
        ) : (
          <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className="MenuList"
          sx={{
            position: 'relative',
            ...(level > 1
              ? {
                  background: '#0B8A6E30',
                  mx: 1.5,
                  my: 1
                }
              : {
                  background: '#0B8A6E40',
                  mx: 3,
                  my: 2
                }),
            borderRadius: '5px',
            overflow: 'hidden'
          }}
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number
};

export default NavCollapse;
