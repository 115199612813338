export default function componentStyleOverrides(theme) {
  const bgColor = theme.colors?.grey50;
  return {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'uppercase',
          fontWeight: 500,
          borderRadius: ownerState.rounded ? '50%' : '4px',
          height: 40,
          minWidth: 100,
          ...(Boolean(ownerState.endIcon) && {
            height: 50,
            minWidth: 'unset',
            lineHeight: 0,
            '& *': {
              margin: 0
            },
            alignItems: 'center',
            justifyContent: 'space-around',
            position: 'relative',
            zIndex: 9,
            '& > .MuiButton-endIcon': {
              borderRadius: '50%',
              padding: '2px'
            },
            '&:not(:disabled)::before': {
              content: '""',
              position: 'absolute',
              width: '0%',
              height: '100%',
              backgroundImage: 'linear-gradient(to right, #59c183, #59c183 100%)',
              transition: 'ease-in-out 0.5s',
              left: '0',
              bottom: '0',
              zIndex: -1,
              borderRadius: '4px'
            },
            '&:not(:disabled):hover': {
              backgroundColor: theme.colors?.primaryMain,
              '& > .MuiButton-endIcon': {
                transition: 'ease-in-out 0.5s',
                backgroundColor: 'white',
                color: theme.colors?.primaryMain
              },
              '&::before': {
                width: '100%'
              }
            }
          }),
          '& .MuiLoadingButton-loadingIndicatorEnd': {
            position: 'unset',
            marginLeft: '10px'
          }
        })
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          fontSize: '16px'
        },
        rounded: {
          borderRadius: theme?.customization?.borderRadius
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: '24px'
        },
        title: {
          fontSize: '1.125rem'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: theme.menuSelected,
            backgroundColor: theme.menuSelectedBack,
            '& *': {
              color: theme.menuSelected
            },
            '&:hover': {
              backgroundColor: theme.menuSelectedBack
            },
            '& .MuiListItemIcon-root path': {
              fill: theme.menuSelected,
              fillOpacity: 1
            }
          },
          '&:hover': {
            backgroundColor: theme.menuSelectedBack,
            color: theme.menuSelected,
            '& *': {
              color: theme.menuSelected
            },
            '& .MuiListItemIcon-root path': {
              fill: theme.menuSelected,
              fillOpacity: 1
            }
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: '36px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&:not(.MuiInputBase-multiline)': {
            height: '50px'
          },
          background: 'white',
          borderRadius: theme?.customization?.borderRadius,
          fontSize: '16px',
          lineHeight: '1.3em',
          '&.Mui-disabled': {
            background: '#EEE'
          },
          '&.PhoneInput .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          }
        },
        input: {
          textAlign: 'left',
          background: 'transparent !important',
          color: theme.textDark,
          padding: '12px 14px !important',
          '&::placeholder': {
            color: '#373737',
            fontSize: '16px'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors?.grey400
          },
          '&:hover $notchedOutline': {
            borderColor: theme.colors?.primaryLight
          },
          '&.MuiInputBase-multiline': {
            padding: 1
          }
        },
        input: {
          fontWeight: 500,
          background: bgColor,
          lineHeight: '1.3em',
          borderRadius: theme?.customization?.borderRadius,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0
            }
          }
        },
        inputAdornedStart: {
          padding: '4px 14px 5px 3px !important'
        },
        notchedOutline: {
          borderRadius: theme?.customization?.borderRadius,
          fontSize: '18px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          lineHeight: '1.25em'
        },
        shrink: {
          fontSize: '18px'
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.colors?.grey300
          }
        },
        mark: {
          backgroundColor: theme.paper,
          width: '4px'
        },
        valueLabel: {
          color: theme?.colors?.primaryLight
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          autoComplete: 'new-password',
          inputProps: {
            min: 0
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700,
          fontSize: '14px',
          marginBottom: 0
        },
        arrow: {
          color: theme.colors?.grey700
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#373737',
          fontWeight: 500,
          '&[data-shrink="true"]': {
            color: '#373737 !important'
          },
          overflow: 'hidden !important'
        },
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0
        },
        label: {
          fontSize: 15
        }
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&:disabled .MuiButton-endIcon': {
            display: 'none'
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '68px',
          '&.child:hover': {
            backgroundColor: 'white'
          },
          '&:not(.child)&:nth-of-type(odd)': {
            backgroundColor: 'white'
          },
          '&:not(.child):nth-of-type(even)': {
            backgroundColor: '#F0F0F0'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '13.5px',
          fontSize: '15px',
          color: '#000',
          whiteSpace: 'nowrap',
          borderBottom: 0
        },
        head: {
          borderBottom: '1px solid #CCC'
        },
        footer: {
          borderTop: '1px solid #CCC'
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          position: 'sticky',
          bottom: '-2px'
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          marginInline: '10px'
        }
      }
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave'
      },
      styleOverrides: {
        root: {
          borderRadius: theme?.customization?.borderRadius
        }
      }
    }
  };
}
