import Loader from 'ui-component/Loader';
import RHFTextField from '../RHFTextField';

const RHFZipCodeField = ({ name, required, loading, error }) => {
  return (
    <RHFTextField
      name={name}
      required={required}
      additionalError={error}
      beforeValueChange={(value) => {
        let updatedValue = value.replace(/\D/g, '');
        if (updatedValue.length > 5) {
          updatedValue = updatedValue.slice(-5);
        } else {
          updatedValue = updatedValue.padStart(5, '0');
        }
        return updatedValue;
      }}
      InputProps={{
        endAdornment: loading && <Loader size={16} />
      }}
    />
  );
};

export default RHFZipCodeField;
