import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: '#000',
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.successLight,
    menuSelectedBack: color.successDark,
    divider: color.grey200,
    customization
  };

  const themeOptions = {
    direction: 'ltr',
    palette: themePalette(themeOption),
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 1920
      }
    },
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px 0',
        // boxShadow: '0px 4px 24px 0px #0000000D',
        '@media (min-width: 600px)': {
          padding: '16px 0',
          minHeight: '48px'
        }
      }
    },
    typography: themeTypography(themeOption)
  };

  const theme = createTheme(themeOptions);
  theme.components = componentStyleOverrides(themeOption);

  return theme;
};

export default theme;
