// action - state management
import { GET_OPTIONS } from './constant';

export const initialState = {
  get_options: {}
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const optionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OPTIONS:
      return {
        ...state,
        get_options: action.payload
      };
    default:
      return state;
  }
};

export default optionReducer;
