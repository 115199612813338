import React, { useState } from 'react';
import Signup from './signUp';
import ThankYou from './thankYou';

const SignupOrThankYou = () => {
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [title, setTitle] = useState('');

  return <>{isSignedUp ? <ThankYou title={title} /> : <Signup setIsSignedUp={setIsSignedUp} setTitle={setTitle} />}</>;
};

export default SignupOrThankYou;
