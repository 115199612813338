import 'react-international-phone/style.css';
import React, { useState } from 'react';

import { InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone';

const excludedCountries = ['Israel'];
const countries = defaultCountries.filter((c) => !excludedCountries.includes(c[0]));

const countryOptions = countries.map((c) => {
  const country = parseCountry(c);
  return (
    <MenuItem key={country.iso2} value={country.iso2}>
      <FlagImage iso2={country.iso2} style={{ marginRight: '8px' }} />
      <Typography marginRight="8px">{country.name}</Typography>
      <Typography>+{country.dialCode}</Typography>
    </MenuItem>
  );
});

const CountrySelect = React.memo(({ country, setCountry, disabled }) => {
  return (
    <Select
      className="PhoneInput"
      MenuProps={{
        style: {
          height: '300px',
          width: '360px',
          top: '10px',
          left: '-34px'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      }}
      sx={{
        outline: 'none',
        width: 'max-content',
        fieldset: {
          display: 'none'
        },
        '&.Mui-focused:has(div[aria-expanded="false"])': {
          fieldset: {
            display: 'block'
          }
        },
        '.MuiSelect-select': {
          padding: '8px',
          paddingRight: '24px !important'
        },
        svg: {
          right: 0
        }
      }}
      value={country}
      onChange={(e) => setCountry(e.target.value, { focusOnInput: true })}
      renderValue={(value) => <FlagImage iso2={value} style={{ display: 'flex' }} />}
      disabled={disabled}
    >
      {countryOptions}
    </Select>
  );
});

export const MuiPhone = React.memo(({ inputRef, value, onChange, ...restProps }) => {
  const [onFocus, setFocus] = useState(null);
  const {
    inputValue,
    handlePhoneValueChange,
    inputRef: phoneInputRef,
    country,
    setCountry
  } = usePhoneInput({
    defaultCountry: 'us',
    value: value,
    countries,
    inputRef,
    onChange: (data) => {
      onChange({ target: { name: restProps.name, value: !onFocus && data.phone == '+1' ? '' : data.phone } });
    }
  });

  const handleFocus = () => {
    setFocus(true);
  };

  return (
    <TextField
      variant="outlined"
      label="Phone number"
      color="primary"
      value={!onFocus && inputValue == '+1 ' ? '' : inputValue}
      onChange={handlePhoneValueChange}
      onFocus={handleFocus}
      type="tel"
      inputRef={phoneInputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{ marginLeft: '-14px', marginRight: '0px' }}>
            <CountrySelect country={country.iso2} setCountry={setCountry} disabled={restProps.disabled} />
          </InputAdornment>
        )
      }}
      {...restProps}
    />
  );
});
