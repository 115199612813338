import { Outlet, useLocation } from 'react-router-dom';
import React from 'react';
// material-ui
import { Grid, Typography, Box, Link } from '@mui/material';

// assets
import userLogo from 'assets/images/infographic.png';
import loginBg from 'assets/images/login_bg.png';

import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from 'assets/images/icons/circular-central-logo.svg';

// ==============================|| AUTH LAYOUT ||============================== //

const AuthLayout = () => {
  const { pathname } = useLocation();
  const tenant = useSelector((state) => state.auth.tenant);

  return (
    <Box
      sx={{
        backgroundImage: `url(${loginBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right'
      }}
    >
      <Grid
        container
        mx={'auto'}
        sx={{
          maxWidth: { xs: 400, sm: 700, md: 1000, lg: 1200, xl: 1400 }
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={userLogo} alt="" style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={7} sx={{ textAlign: 'center' }}>
          {pathname !== '/login' && (
            <Box sx={{ position: 'absolute', right: '28px', top: '12px', color: '#0B8A6E' }}>
              <Link href="/login" style={{ textDecoration: 'none', display: 'inline-flex', alignItems: 'center' }}>
                <svg className="me-1.5" width="17" height="17" viewBox="0 0 17 17" fill="#0B8A6E" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_445_1513)">
                    <path
                      d="M7.61976 15.2203H15.2131V1.78272H7.61976C7.61976 2.04126 7.62342 2.3042 7.61976 2.5664C7.61096 3.13402 7.2439 3.52513 6.7303 3.51927C6.23209 3.51341 5.87016 3.12743 5.86136 2.58325C5.8555 2.20532 5.85257 1.82667 5.86283 1.44801C5.88408 0.637231 6.50025 0.00955461 7.31424 0.00662496C10.061 -0.00216398 12.8078 -0.00289639 15.5552 0.00662496C16.3663 0.00955461 16.9935 0.642358 16.9949 1.45753C17.0015 6.15595 17.0023 10.8551 16.9942 15.5535C16.9927 16.365 16.3575 16.989 15.5413 16.992C12.7946 17.0008 10.0478 17.0015 7.30032 16.9912C6.50464 16.9883 5.8892 16.3636 5.86356 15.5733C5.85037 15.1712 5.85184 14.7676 5.86283 14.3656C5.87675 13.8543 6.2592 13.4786 6.74422 13.4808C7.23071 13.483 7.60217 13.858 7.61829 14.3743C7.62708 14.6424 7.61976 14.9105 7.61976 15.221V15.2203Z"
                      fill="#0B8A6E"
                    ></path>
                    <path
                      d="M10.4793 9.37995C8.77512 9.37995 7.17645 9.37995 5.57777 9.37995C4.05237 9.37995 2.52623 9.38141 1.00082 9.37922C0.399306 9.37848 0.00146935 9.02473 4.02685e-06 8.50179C-0.0014613 7.97811 0.397108 7.6207 0.99716 7.6207C3.98716 7.61923 6.9779 7.61997 9.9679 7.61997C10.102 7.61997 10.2361 7.61997 10.4287 7.61997C10.3467 7.51743 10.2983 7.44419 10.2383 7.3834C9.73931 6.88169 9.2345 6.38512 8.73849 5.88049C8.3216 5.45715 8.30109 4.91444 8.67768 4.55263C9.04327 4.2018 9.57006 4.2289 9.98035 4.63612C11.0449 5.69372 12.1073 6.75425 13.1645 7.81991C13.5975 8.25716 13.6012 8.73763 13.1704 9.17195C12.1051 10.2471 11.0347 11.3164 9.95984 12.3814C9.55687 12.7805 9.0125 12.7886 8.65789 12.4231C8.30402 12.0584 8.33039 11.5325 8.73629 11.1216C9.29092 10.5599 9.85287 10.0054 10.48 9.37922L10.4793 9.37995Z"
                      fill="#0B8A6E"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_445_1513">
                      <rect width="17" height="17" fill="#0B8A6E"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <Typography variant="h6" sx={{ color: '#0B8A6E', ml: '10px' }}>
                  Login
                </Typography>
              </Link>
            </Box>
          )}
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              py: 3,
              minHeight: { md: '100vh' },
              '& img': {
                width: {
                  xs: 160,
                  sm: 180,
                  md: 210,
                  lg: 250,
                  xl: 300
                }
              }
            }} // Adjust the height as needed
          >
            <Typography>{tenant?.logo_url ? <img src={tenant?.logo_url} alt="logo" /> : <Logo />}</Typography>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 600,
                fontSize: { xs: '1.75rem', sm: '2rem', lg: '3rem', xl: '3rem' },
                fontFamily: 'Tungsten',
                mt: 3,
                mb: 2,
                textTransform: 'uppercase'
              }}
            >
              WELCOME TO {tenant?.enterprise_name ?? 'BONSAI'}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                minHeight: '110px',
                width: '100%',
                maxWidth: { md: '500px', lg: '650px' }
              }}
            >
              <Outlet />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthLayout;
