import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, IconButton, Toolbar, Typography } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { drawerWidth, largeDrawerWidth } from 'store/customization/constant';
import { SET_MENU } from 'store/customization/actions';

// assets
import { IconChevronRight } from '@tabler/icons-react';
import GoBackButton from 'ui-component/GobackButton';
import { ReactComponent as Add } from 'assets/images/icons/Add.svg';
import { getSplashData } from 'store/financialTool/action';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'portal' && prop !== 'open' && prop !== 'theme' })(
  ({ theme, open, portal }) => ({
    ...theme.typography.mainContent,
    position: 'relative',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    minHeight: `calc(100vh - ${!portal ? 82 : 110}px)`,
    maxHeight: `calc(100vh - ${!portal ? 82 : 110}px)`,
    marginTop: `${!portal ? 82 : 110}px`,
    boxShadow: '0 0 #0000, 0 0 #0000, 0px 4px 24px rgba(0, 0, 0, 0.05)',
    transition: theme.transitions.create(
      'margin',
      open
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
          }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          }
    ),
    [theme.breakpoints.up('xl')]: {
      marginLeft: open ? 0 : -largeDrawerWidth,
      width: `calc(100% - ${largeDrawerWidth}px)`
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: open ? 0 : -drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      minHeight: `calc(100vh - ${!portal ? 80 : 110}px)`,
      maxHeight: `calc(100vh - ${!portal ? 80 : 110}px)`,
      marginTop: `${!portal ? 80 : 110}px`
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px'
    },
    overflowY: 'auto',
    overflowX: 'hidden'
  })
);

// ==============================|| MAIN LAYOUT ||============================== //
const MainLayout = () => {
  const errorPage = localStorage.getItem('ErrorPage') == 'true';
  const location = useLocation();
  const theme = useTheme();
  // Handle left drawer
  const auth = useSelector((state) => state?.auth?.login);
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const portal = useSelector((state) => state.clientManagement.client_portal);
  const client_id = portal?.household_id ?? auth?.user?.id;
  const editClient = useSelector((state) => state.clientManagement?.view_client?.data?.client);
  const [formattedTitle, setFormattedTitle] = React.useState('');
  const [isBonsaiExchange, setIsBonsaiExchange] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    let title = location.pathname;
    setIsBonsaiExchange(title.includes('/bonsai_exchange/'));
    let formatted = title.replace('/', '').split('/').at(-1).replace(/_/g, ' ');
    formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1);
    setFormattedTitle(formatted);
  }, [location, portal]);

  useEffect(() => {
    client_id && portal && dispatch(getSplashData(client_id));
  }, [dispatch, client_id, portal]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        {portal && (
          <Box
            sx={{
              display: {
                xs: 'flex',
                md: 'none'
              },
              height: '30px',
              width: '100%',
              bgcolor: '#282828',
              color: 'white',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography>
              You are filling the data for {portal?.first_name ?? ''} {portal?.last_name ?? ''}.
            </Typography>
          </Box>
        )}
        <Toolbar>
          <Header
            handleLeftDrawerToggle={handleLeftDrawerToggle}
            formattedTitle={formattedTitle}
            open={open}
            setOpen={setOpen}
            handleOpen={handleOpen}
            handleClose={handleClose}
            isBonsaiExchange={isBonsaiExchange}
          />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened} portal={portal}>
        <Box
          sx={{
            mx: { xs: formattedTitle == 'User management' || formattedTitle == 'Client management' ? '3px' : '15px', sm: '15px' },
            mb: '25px',
            display: { xs: 'flex', sm: 'none' },
            alignItems: 'center',
            justifyContent: {
              xs: formattedTitle == 'User management' || formattedTitle == 'Client management' ? 'space-between' : 'flex-start',
              sm: 'flex-start'
            }
          }}
        >
          {!errorPage && (
            <>
              <GoBackButton
                title={formattedTitle}
                portal={formattedTitle == 'Bonsai exchange' && portal}
                client={['Edit client', 'Client details'].includes(formattedTitle) && editClient}
                isBonsaiExchange={isBonsaiExchange}
              />
              <Typography variant="h1" sx={{ fontFamily: 'Tungsten', textTransform: 'uppercase' }}>
                {formattedTitle == 'Dashboard' && auth?.user?.first_name
                  ? `${auth?.user?.first_name ?? ''} ${auth?.user?.last_name ?? ''}`
                  : (formattedTitle == 'Bonsai exchange' && portal) ||
                      (['Edit client', 'Client details'].includes(formattedTitle) && editClient)
                    ? `${(portal || editClient).first_name ?? ''} ${(portal || editClient).last_name ?? ''}`
                    : formattedTitle.toUpperCase()}
              </Typography>
              {formattedTitle == 'User management' || formattedTitle == 'Client management' || formattedTitle == 'Tenants' ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleOpen}>
                  <IconButton sx={{ p: 0, m: 0, marginLeft: '10px' }}>
                    <Add style={{ width: 40, height: 40, cursor: 'pointer' }} />
                  </IconButton>
                </Box>
              ) : (
                ''
              )}
            </>
          )}
        </Box>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
      </Main>
    </Box>
  );
};

export default MainLayout;
