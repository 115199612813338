import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/customization/actions';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import BasicModal from 'ui-component/modal';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
  const portal = useSelector((state) => state.clientManagement.client_portal);
  const splashData = useSelector((state) => state.financialPlan.splashData?.bonsai_exchange_material);
  const hasActiveSplashUrl = useMemo(
    () => splashData?.find((splashItem) => splashItem.title === item.id && splashItem?.status === 1 && splashItem?.admin_status === 1),
    // eslint-disable-next-line
    [splashData]
  );
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    !item.target ? navigate(item.url, { replace: true }) : window.open(item.url, '_blank');
  };
  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon width={30} height={30} {...item?.iconProps} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let listItemProps = {};
  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  listItemProps = {
    component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (e, id) => {
    if (hasActiveSplashUrl) {
      e.preventDefault();
      e.stopPropagation();
      setOpen(true);
    } else {
      dispatch({ type: MENU_OPEN, id });
      if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    }
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname.includes(item.url);
    if (currentIndex) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
    if (document.location.pathname === '/bonsai_exchange') {
      dispatch({ type: MENU_OPEN, id: null });
    }
    // eslint-disable-next-line
  }, [pathname, item]);
  return (
    <>
      <ListItemButton
        {...listItemProps}
        disabled={Boolean(item.disabled || (portal && item.disabledOnPortal))}
        sx={{
          mb: level > 1 ? 0 : 0.5,
          alignItems: 'flex-start',
          backgroundColor: level > 1 ? 'transparent' : 'inherit',
          py: level > 1 ? 1 : 1.25,
          pl: 2.5,
          '&.Mui-selected svg, &:hover svg': {
            fillOpacity: 1
          }
        }}
        selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
        onClick={(e) => itemHandler(e, item.id)}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 42 }}>{itemIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                {item.caption}
              </Typography>
            )
          }
        />
        {item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
      <BasicModal
        open={open}
        modalClose={handleClose}
        isDND={'pdf-splash'}
        width={'80%'}
        // height={pdfUrl && '100%'}
        addpdfurl={hasActiveSplashUrl?.pdf_url ? hasActiveSplashUrl?.pdf_url : null}
      />
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
