import { useEffect } from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!document.getElementById('hs-script-loader')) {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = '//js.hs-scripts.com/46611577.js';

      document.body.appendChild(script);
    }

    const sendPageView = () => {
      let _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['setPath', window.location.pathname]);
      _hsq.push(['trackPageView']);
      console.log('set new path: ', window.location.pathname);
    };

    sendPageView();
  }, [pathname]);

  useEffect(() => {
    if (pathname === '/') navigate('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return useRoutes([MainRoutes, AuthenticationRoutes]);
}
