import config from 'config';
import pages from 'menu-items/pages';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import ErrorPage from 'views/pages/ErrorPage';
const { ROLES } = config;
const Access = JSON.parse(localStorage.getItem('Roles')) || [];
export const ProtectedRoute = ({ isAuthenticated, children, roles, notRoles, status, subscriptions, redirect, noHeading }) => {
  const auth = useSelector((state) => state?.auth?.login);

  // Needs to be removed until live deployment around 1st Jan
  const obsoleteKey = 'Role';
  if (localStorage.getItem(obsoleteKey)) {
    localStorage.clear();
    return <Navigate to="/login" />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  localStorage.setItem('ErrorPage', noHeading ?? false);

  if (
    (roles?.includes(ROLES.CLIENT) && status && auth?.user?.status == 1) ||
    (((roles && roles.some((role) => Access.includes(role))) || (notRoles && !notRoles.some((role) => Access.includes(role)))) &&
      (!subscriptions || subscriptions?.includes(auth?.user?.subscription))) ||
    (!roles && !notRoles && !status && !subscriptions)
  ) {
    return children;
  } else {
    if (redirect) {
      return <Navigate to={redirect} />;
    }
    localStorage.setItem('ErrorPage', true);
    return <ErrorPage />;
  }
};

export const PublicRoute = ({ isAuthenticated, isClient, children }) => {
  // Needs to be removed until live deployment around 1st Jan
  const obsoleteKey = 'Role';
  if (localStorage.getItem(obsoleteKey)) {
    localStorage.clear();
    return <Navigate to="/login" />;
  }

  if (!isAuthenticated) {
    return children;
  } else {
    return <Navigate to={isClient ? '/dashboard' : pages.children[0]?.url} />;
  }
};
