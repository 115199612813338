// action - state management
import {
  GET_CLIENTS,
  VIEW_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  DELETE_ALLCLIENT,
  CLIENT_PORTAL,
  RESET_CLIENT_PORTAL,
  CLEAR_CLIENT,
  GET_HOUSEHOLD,
  CLEAR_HOUSEHOLD
} from './constant';

export const initialState = {
  get_clients: '',
  view_client: '',
  update_client: '',
  delete_client: '',
  delete_allClient: '',
  client_portal: '',
  get_household: ''
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_CLIENT:
      return {
        ...state,
        view_client: action.payload
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        update_client: action.payload
      };
    case DELETE_CLIENT:
      return {
        ...state,
        delete_client: action.payload
      };
    case GET_CLIENTS:
      return {
        ...state,
        get_clients: action.payload
      };
    case DELETE_ALLCLIENT:
      return {
        ...state,
        delete_allClient: action.payload
      };
    case CLIENT_PORTAL:
      return {
        ...state,
        client_portal: action.payload
      };
    case RESET_CLIENT_PORTAL:
      return {
        ...state,
        client_portal: ''
      };
    case CLEAR_CLIENT:
      return {
        ...state,
        view_client: ''
      };
    case GET_HOUSEHOLD:
      return {
        ...state,
        get_household: action.payload
      };
    case CLEAR_HOUSEHOLD:
      return {
        ...state,
        get_household: ''
      };
    default:
      return state;
  }
};

export default userManagementReducer;
