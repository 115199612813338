// action - state management
import { GET_USERS, UPDATE_USER, ADD_USER, GET_USER, GET_ROLES, GET_TENANTS } from './constant';

export const initialState = {
  get_users: '',
  get_user: '',
  update_user: '',
  add_user: '',
  get_roles: '',
  get_tenants: ''
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        get_users: action.payload
      };
    case UPDATE_USER:
      return {
        ...state,
        update_user: action.payload
      };
    case ADD_USER:
      return {
        ...state,
        add_user: action.payload
      };
    case GET_USER:
      return {
        ...state,
        get_user: action.payload
      };
    case GET_ROLES:
      return {
        ...state,
        get_roles: action.payload
      };
    case GET_TENANTS:
      return {
        ...state,
        get_tenants: action.payload
      };
    default:
      return state;
  }
};

export default userManagementReducer;
