import axios from 'utils/axios';
import { toast } from 'react-toastify';
import { GET_OPTIONS } from './constant';

export const getOptions = () => async (dispatch) => {
  const url = `financial-plan/get-financial-options`;
  try {
    const response = await axios.get(url);

    dispatch({
      type: GET_OPTIONS,
      payload: response.data?.financialPlanOption
    });
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
};
