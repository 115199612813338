import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const withController =
  (Component) =>
  ({ name, rules, defaultValue, ...props }) => {
    const { control } = useFormContext();
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { ref, ...field }, fieldState }) => (
          <Component id={field.name} inputRef={ref} {...field} invalid={fieldState.invalid} error={fieldState.error} {...props} />
        )}
      />
    );
  };

export default withController;
