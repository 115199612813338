import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Grid, Button, Typography, IconButton } from '@mui/material';
import { ReactComponent as HamburgerOpen } from 'assets/images/icons/HamburgerOpen.svg';
import { ReactComponent as HamburgerClose } from 'assets/images/icons/HamburgerClose.svg';
import { ReactComponent as AddIcon } from 'assets/images/icons/Add.svg';
import BasicModal from 'ui-component/modal';
import GoBackButton from 'ui-component/GobackButton';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// assets
// import { IconMenu2 } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, formattedTitle, open, setOpen, handleOpen, handleClose, isBonsaiExchange }) => {
  const errorPage = localStorage.getItem('ErrorPage') == 'true';
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const auth = useSelector((state) => state?.auth?.login);
  const portal = useSelector((state) => state.clientManagement.client_portal);
  const editClient = useSelector((state) => state.clientManagement?.view_client?.data?.client);
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          width: { xs: '260px', xl: '260px' },
          // boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.05)',
          display: 'flex',
          px: '20px',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '5px', overflow: 'hidden' }} disableRipple>
          <Avatar
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '& #arrow': {
                display: { xs: 'none', md: 'block' }
              },
              '& svg': {
                marginLeft: { xs: '-15px', md: '0' }
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            {leftDrawerOpened ? <HamburgerClose width={35} /> : <HamburgerOpen width={35} />}
          </Avatar>
        </ButtonBase>
      </Box>
      <Grid flexGrow={1} sx={{ mt: '-16px' }}>
        {portal && (
          <Grid
            xs={12}
            item
            sx={{
              display: {
                xs: 'none',
                md: 'flex'
              },
              height: '30px',
              width: '100%',
              bgcolor: '#282828',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="h4" color={'white'} fontWeight={500}>
              You are filling the data for {portal?.first_name ?? ''} {portal?.last_name ?? ''}.
            </Typography>
          </Grid>
        )}
        <Grid
          xs={12}
          item
          sx={{
            pt: '16px',
            pr: { xs: 0, md: 2 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'flex-end', sm: 'space-between' }
          }}
        >
          <Box sx={{ ml: 2, display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
            {!errorPage && (
              <>
                <GoBackButton
                  title={formattedTitle}
                  portal={formattedTitle == 'Bonsai exchange' && portal}
                  client={['Edit client', 'Client details'].includes(formattedTitle) && editClient}
                  isBonsaiExchange={isBonsaiExchange}
                />
                <Typography variant="h1" sx={{ fontFamily: 'Tungsten', textTransform: 'uppercase' }}>
                  {formattedTitle == 'Dashboard' && auth?.user?.first_name
                    ? `${auth?.user?.first_name ?? ''} ${auth?.user?.last_name ?? ''}`
                    : (formattedTitle == 'Bonsai exchange' && portal) ||
                        (['Edit client', 'Client details'].includes(formattedTitle) && editClient)
                      ? `${(portal || editClient).first_name ?? ''} ${(portal || editClient).last_name ?? ''}`
                      : formattedTitle.toUpperCase()}
                </Typography>
                {formattedTitle == 'User management' || formattedTitle == 'Client management' || formattedTitle == 'Tenants' ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handleOpen}>
                    {formattedTitle == 'Client management' ? (
                      <Button variant="contained" sx={{ m: 0, marginLeft: '20px', borderRadius: '20px' }}>
                        <Add style={{ width: 30, height: 30, cursor: 'pointer' }} /> <Typography>Create Household</Typography>
                      </Button>
                    ) : (
                      <IconButton sx={{ p: 0, m: 0, marginLeft: '20px' }}>
                        <AddIcon style={{ width: 40, height: 40, cursor: 'pointer' }} />
                      </IconButton>
                    )}
                  </Box>
                ) : (
                  ''
                )}
              </>
            )}
          </Box>
          <ProfileSection />
        </Grid>
      </Grid>
      <BasicModal isClient={formattedTitle} open={open} setOpen={setOpen} modalClose={handleClose} disableBackdrop />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
