// action - state management
import { UPDATE_TENANT, ADD_TENANT, GET_TENANT, GET_ROLES, GET_TENANTS } from './constant';

export const initialState = {
  get_tenant: [],
  list: '',
  update_tenant: '',
  add_tenant: '',
  get_roles: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TENANT:
      return {
        ...state,
        update_tenant: action.payload
      };
    case ADD_TENANT:
      return {
        ...state,
        add_tenant: action.payload
      };
    case GET_TENANTS:
      return {
        ...state,
        list: action.payload
      };
    case GET_TENANT:
      return {
        ...state,
        get_tenant: action.payload
      };
    case GET_ROLES:
      return {
        ...state,
        get_roles: action.payload
      };
    default:
      return state;
  }
};

export default tenantReducer;
