import { Children } from 'react';
import withController from './withController';
import { MenuItem } from '@mui/material';
import RHFTextField from './RHFTextField';

const RHFSelect = ({ options = [], optionLabel, optionValue, ...props }) => {
  return (
    <RHFTextField select {...props}>
      {Children.toArray(
        options?.map((option) => (
          <MenuItem value={option?.[optionValue] ?? option?.value ?? option}>{option?.[optionLabel] ?? option?.label ?? option}</MenuItem>
        ))
      )}
    </RHFTextField>
  );
};

export default withController(RHFSelect);
