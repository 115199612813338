export const GET_CLIENTS = 'GET_CLIENTS';
export const VIEW_CLIENT = 'VIEW_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_ALLCLIENT = 'DELETE_ALLCLIENT';
export const CLIENT_PORTAL = 'CLIENT_PORTAL';
export const RESET_CLIENT_PORTAL = 'RESET_CLIENT_PORTAL';
export const CLEAR_CLIENT = 'CLEAR_CLIENT';
export const GET_HOUSEHOLD = 'GET_HOUSEHOLD';
export const CLEAR_HOUSEHOLD = 'CLEAR_HOUSEHOLD';
