import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { MENU_OPEN } from 'store/customization/actions';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ width }) => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const loginData = useSelector((state) => state?.auth?.login);
  const dispatch = useDispatch();
  return (
    <ButtonBase disableRipple onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} component={Link} to={config.DEFAULT_PATH}>
      {
        <>
          {!loginData?.user?.logo_url ? (
            <Logo width={width ?? 170} />
          ) : (
            <img src={loginData?.user?.logo_url} alt="" style={{ height: '55px', width: '170px' }} />
          )}
        </>
      }
    </ButtonBase>
  );
};

export default LogoSection;
