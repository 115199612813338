import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();
let phoneNumber;

const isPhoneValid = (phone) => {
  try {
    phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(phoneNumber);
  } catch (error) {
    return false;
  }
};

export const yupPhone = (key) => {
  const keyName = key ?? 'Phone number';
  return Yup.string()
    .test('min', `${keyName} must be at least 11 digits.`, (v) => {
      if (!v) {
        return true;
      }
      return v.length >= 11;
    })
    .test('phone', `Invalid ${keyName}.`, (v) => {
      if (!v) {
        return true;
      }
      return isPhoneValid(v);
    });
};

// import { phone } from 'phone';
// import * as Yup from 'yup';

// export const yupPhone = (key) => {
//   const keyName = key ?? 'Phone number';
//   return Yup.string().test('phone', `Invalid ${keyName}.`, (v) => {
//     if (!v) {
//       return true;
//     }
//     return phone(v, { strictDetection: false }).isValid || phone(v, { country: 'PK' }).isValid;
//   });
// };
