import axios from 'axios';
import config from '../config';

const axiosClient = axios.create({
  baseURL: config.API_BASE_URL
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('Token');
    return {
      ...config,
      headers: {
        ...(token !== null && { Authorization: `Bearer ${token}` }),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...config.headers
      }
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = config.EXTERNAL_URLS.LOGOUT_URL;
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
