// action - state management
import { GET_LONG_TERM_CARE, RESET_LONG_TERM_CARE, GET_MEDICARE, GET_PERMANENT_LIFE_INSURANCE } from './constant';

export const initialState = {
  longTermCare: '',
  getMedicare: '',
  permanentLifeInsurance: ''
};

// ==============================|| LONG-TERM CARE REDUCER ||============================== //

const longTermCareReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LONG_TERM_CARE:
      return {
        ...state,
        longTermCare: action.payload
      };
    case GET_PERMANENT_LIFE_INSURANCE:
      return {
        ...state,
        permanentLifeInsurance: action.payload
      };
    case RESET_LONG_TERM_CARE:
      return {
        ...initialState
      };
    case GET_MEDICARE:
      return {
        ...state,
        getMedicare: action.payload
      };
    default:
      return state;
  }
};

export default longTermCareReducer;
