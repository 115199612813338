export const FieldTypes = {
  Currency: 'currency',
  Numeric: 'numeric',
  Percentage: 'percentage',
  Date: 'date',
  MonthAndYear: 'month-and-year',
  Select: 'select',
  Text: 'text'
};

export const DateFormats = {
  [FieldTypes.Date]: 'MM/DD/YYYY',
  [FieldTypes.MonthAndYear]: 'MM/YYYY'
};

const labelCache = new Map();

export function transformLabel(s) {
  if (typeof s != 'string') return;

  if (labelCache.has(s)) {
    return labelCache.get(s);
  }

  let original = s;

  if (s.includes('.')) {
    s = s.split('.').at(-1);
  }
  s = s.replace(/_/g, ' ');

  const transformed = s
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  labelCache.set(original, transformed);
  return transformed;
}
