import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customization/customizationReducer';
import myAccountReducer from './MyAccount/reducer';
import userManagementReducer from './userManagement/reducer';
import loginReducer from './Authentication/reducer';
import clientManagementReducer from './clientManagement/reducer';
import longTermCareReducer from './LongtermCare/reducer';
import bonsaiReducer from './BonsaiExchange/reducer';
import formSubmissionReducer from './formSubmission/reducer';
import tenantReducer from './Tenant/reducer';
import financialPlanReducer from './financialTool/reducer';
import optionReducer from './Options/reducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  myAccount: myAccountReducer,
  userManagement: userManagementReducer,
  clientManagement: clientManagementReducer,
  longTermCare: longTermCareReducer,
  bonsaiReducer: bonsaiReducer,
  formSubmissionReducer: formSubmissionReducer,
  tenants: tenantReducer,
  financialPlan: financialPlanReducer,
  auth: loginReducer,
  options: optionReducer
});

export default reducer;
