// action - state management
import { UPDATE_ACCOUNT, GET_PROFILE } from './constant';

export const initialState = {
  update_account: '',
  get_profile: ''
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const myAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACCOUNT:
      return {
        ...state,
        update_account: action.payload
      };
    case GET_PROFILE:
      return {
        ...state,
        get_profile: action.payload
      };
    default:
      return state;
  }
};

export default myAccountReducer;
