import { useMemo } from 'react';
import withController from './withController';
import { transformLabel } from './utils';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

const RHFCheckbox = ({ name, invalid, error, ...props }) => {
  const label = useMemo(() => props.label ?? transformLabel(name) ?? '', [props.label, name]);

  return (
    <FormControlLabel
      control={<Checkbox {...props} checked={props.value} inputProps={{ 'aria-label': 'controlled' }} />}
      error={invalid}
      helperText={error?.message}
      label={
        <Typography variant="h5" sx={{ color: 'black' }}>
          {label}
        </Typography>
      }
    />
  );
};

export default withController(RHFCheckbox);
