import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';

const MAX_LIMIT = 100;

const CustomNumericFormat = React.forwardRef((props, ref) => {
  const { onChange, value, ...other } = props;

  return (
    <NumericFormat
      {...other}
      value={value ?? ''}
      getInputRef={ref}
      autoComplete="off"
      isAllowed={(values) => {
        const { floatValue } = values;
        return !floatValue || (floatValue <= (other?.max ?? MAX_LIMIT) && floatValue >= (other?.min ?? -MAX_LIMIT));
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue ?? null
          }
        });
      }}
      decimalScale={2}
      suffix="%"
    />
  );
});

export default function PercentageInput({ ...props }) {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: CustomNumericFormat,
        ...props.InputProps
      }}
    />
  );
}
