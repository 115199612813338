import { useCallback, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import withController from './withController';
import { transformLabel } from './utils';

const RHFTextField = ({
  name,
  invalid,
  additionalError,
  onChange: fieldChange,
  beforeValueChange,
  afterValueChange,
  children,
  type,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const label = useMemo(() => props.label ?? transformLabel(name) ?? '', [props.label, name]);
  const placeholder = useMemo(() => {
    if (props.placeholder === true) {
      return `Enter ${label}`;
    }
    return props.placeholder;
  }, [props.placeholder, label]);

  const onChange = useCallback(
    (e) => {
      if (beforeValueChange) {
        e.target.value = beforeValueChange(e.target.value);
      }
      fieldChange(e);
      afterValueChange && afterValueChange(e.target.value);
    },
    [beforeValueChange, fieldChange, afterValueChange]
  );

  const error = useMemo(() => Boolean(additionalError) || invalid, [additionalError, invalid]);
  const helperText = useMemo(() => additionalError ?? props.error?.message, [additionalError, props.error]);

  const commonProps = {
    ...props,
    onChange: onChange,
    label: label,
    placeholder: placeholder,
    error,
    helperText,
    type: showPassword ? 'text' : type,
    fullWidth: true,
    InputLabelProps: {
      style: { overflow: 'hidden !important' }
    }
  };

  if (!children) {
    return (
      <TextField
        {...commonProps}
        InputProps={{
          endAdornment:
            type === 'password' ? (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : null
        }}
      />
    );
  }
  return <TextField {...commonProps}>{children}</TextField>;
};

export default withController(RHFTextField);
