import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Drawer, useMediaQuery, ButtonBase } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth, largeDrawerWidth } from 'store/customization/constant';
import LogoutButton from 'views/pages/authentication/Logout';
import { useSelector } from 'react-redux';
import { ReactComponent as HamburgerOpen } from 'assets/images/icons/HamburgerOpen.svg';
import { ReactComponent as HamburgerClose } from 'assets/images/icons/HamburgerClose.svg';
import { ReactComponent as Close } from 'assets/images/icons/Close.svg';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

// drawerWidth and other constants...

const Sidebar = ({ drawerOpen, drawerToggle }) => {
  const portal = useSelector((state) => state.clientManagement.client_portal);
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const scrollerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (scrollerRef.current) {
      setTimeout(() => scrollerRef.current?.updateScroll(), 700);
    }
  }, [location]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpXl ? largeDrawerWidth : matchUpMd ? drawerWidth : 'auto' }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: matchUpXl ? largeDrawerWidth : drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            top: 0,
            zIndex: 1
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              minHeight: { xs: `${!portal ? 80 : 110}px`, md: `${!portal ? 82 : 110}px` },
              maxHeight: { xs: `${!portal ? 80 : 110}px`, md: `${!portal ? 82 : 110}px` },
              position: 'relative'
            }}
          >
            <LogoSection width={150} />
            <ButtonBase
              sx={{ overflow: 'hidden', ...(!matchUpMd && { p: 1, borderRadius: '50%', height: 'fit-content' }) }}
              disableRipple={matchUpMd}
            >
              <Avatar
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  transition: 'all .2s ease-in-out',
                  background: theme.palette.secondary.light,
                  color: theme.palette.secondary.dark
                }}
                onClick={drawerToggle}
                color="inherit"
              >
                {matchUpMd ? (
                  leftDrawerOpened ? (
                    <HamburgerClose width={35} height={35} />
                  ) : (
                    <HamburgerOpen width={35} height={35} />
                  )
                ) : (
                  <Close width={25} height={25} />
                )}
              </Avatar>
            </ButtonBase>
          </Box>
          <PerfectScrollbar ref={scrollerRef} component="div" style={{ height: '100%', overflowY: 'auto' }}>
            <MenuList />
          </PerfectScrollbar>
          <LogoutButton />
        </Box>
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
