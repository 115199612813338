// material-ui
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// ==============================|| LOADER ||============================== //
const Loader = ({ size, sx }) => (
  <Box
    ref={(e) => {
      if (e) {
        let mainHeight = e?.closest('main')?.clientHeight;
        let parentHeight = e?.parentNode?.clientHeight;
        if (mainHeight && parentHeight > 120) {
          e.style.maxHeight = `${mainHeight - 96}px`;
          e.style.height = `${parentHeight}px`;
        } else {
          e.style.height = `${parentHeight}px`;
        }
      }
    }}
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...sx }}
  >
    <CircularProgress color="success" size={size ?? 40} />
  </Box>
);

export default Loader;
