import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as Alert } from 'assets/images/icons/Alert.svg';
import { Link } from 'react-router-dom';

const ErrorPage = ({ notFound }) => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} height={'calc(100vh - 200px)'}>
      <Alert width={130} height={130} fill="#0B8A6E" />
      <Typography sx={{ fontSize: '60px', fontWeight: '700', textAlign: 'center', my: 1 }}>{notFound ? 404 : 403}</Typography>
      <Typography variant="h3" sx={{ fontWeight: '700', mb: 2 }}>
        {notFound ? 'PAGE NOT FOUND' : 'ACCESS DENIED'}
      </Typography>
      <Typography sx={{ textAlign: 'center', lineHeight: 1.5 }}>
        {notFound ? <>Sorry, the page you were looking for doesn&apos;t exist.</> : <>Sorry, you don&apos;t have access to this page.</>}
        <br />
        You can go back to the{' '}
        <Link style={{ color: '#0B8A6E' }} to={-1}>
          Previous Page
        </Link>
      </Typography>
    </Box>
  );
};

export default ErrorPage;
