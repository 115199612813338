import { useCallback, useMemo } from 'react';
import withController from './withController';
import { transformLabel } from './utils';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const RHFRadio = ({ name, error, options, invalid, fullWidth, onChange: fieldChange, beforeValueChange, afterValueChange, ...props }) => {
  const label = useMemo(() => props.label ?? transformLabel(name) ?? '', [props.label, name]);
  const onChange = useCallback(
    (e) => {
      if (beforeValueChange) {
        e.target.value = beforeValueChange(e.target.value);
      }
      fieldChange(e);
      afterValueChange && afterValueChange(e.target.value);
    },
    [beforeValueChange, fieldChange, afterValueChange]
  );

  return (
    <Box sx={{ display: 'flex', mx: 1.5, alignItems: 'center', width: fullWidth ? '100%' : 'auto', ...props.sx }}>
      <Typography variant="h5" sx={{ color: 'black', mr: 1, textAlign: 'left' }}>
        {label}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {options?.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Radio
                {...props}
                checked={props.value === option.value}
                value={option.value}
                inputProps={{ 'aria-label': option.label }}
                sx={{ m: 0 }}
                onChange={onChange}
              />
            }
            label={option.label}
          />
        ))}
      </Box>
      {invalid && (
        <Typography variant="body2" sx={{ color: 'error.main', marginTop: '10px' }}>
          {error.message}
        </Typography>
      )}
    </Box>
  );
};

export default withController(RHFRadio);
