// material-ui
import { Typography, Button, Box } from '@mui/material';

// assets
import { Link } from 'react-router-dom';

// ================================|| AUTH3 - LOGIN ||================================ //

const ThankYou = ({ title }) => {
  return (
    <>
      {title === 'sign-up' || title === 'sign-up-welcome' ? (
        <Box sx={{ padding: '10px 45px', textAlign: 'center' }}>
          <Typography variant="h2" sx={{ marginBottom: '10px' }}>
            Your Account is Pending Activation
          </Typography>
          <Typography sx={{ marginBottom: '10px' }}>
            {title === 'sign-up-welcome' && 'Thank you for registering with Bonsai.'} Your account is currently pending activation. One of
            our Customer Success Representatives will contact you shortly to activate your account.
          </Typography>
          <Typography sx={{ marginBottom: '10px' }}>
            If you have any questions in the meantime, please reach out to us at{' '}
            <Link href="tel:1-833-266-7249">1-833-BONSAIX (1-833-266-7249)</Link> or Email us at
            <Link href="mailto:info@mybonsai.com"> info@mybonsai.com</Link>.
          </Typography>
          <Typography>We appreciate your patience and look forward to serving you soon!</Typography>
        </Box>
      ) : (
        <Typography variant="h4">{title}</Typography>
      )}
      <Button
        variant="contained"
        size="large"
        sx={{
          mt: 2,
          padding: { lg: '12px 20px', xl: '12px 20px' },
          width: '100%',
          maxWidth: { xs: '280px', sm: '350px', lg: '500px' },
          mx: 'auto',
          boxShadow: 'none',
          height: 'auto',
          fontSize: { xs: '12px', md: '14px', lg: '18px', xl: '22px' }
        }}
        component={Link}
        to="/login"
      >
        Back to Login
      </Button>
    </>
  );
};

export default ThankYou;
