import { useCallback, useMemo } from 'react';
import withController from './withController';
import { DateFormats, FieldTypes, transformLabel } from './utils';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

// To Date Format
const toDateFormat = (date) => (date ? dayjs(date)?.utc(true)?.format(DateFormats[FieldTypes.MonthAndYear]) : null);

// From Date Format
const fromDateFormat = (date) => (date ? dayjs(date, DateFormats[FieldTypes.MonthAndYear]) : null);

const RHFMonthYearField = ({ inputRef, name, invalid, error, required, InputProps, ...props }) => {
  const value = useMemo(() => fromDateFormat(props.value), [props.value]);
  const label = useMemo(() => props.label ?? transformLabel(name) ?? '', [props.label, name]);
  const placeholder = useMemo(() => {
    if (props.placeholder === true) {
      return `Enter ${label}`;
    }
    return props.placeholder;
  }, [props.placeholder, label]);

  const onChange = useCallback((date) => {
    const event = { target: { id: name, value: toDateFormat(date) ?? null } };
    props.onChange(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatePicker
      {...props}
      label={label}
      placeholder={placeholder}
      value={value}
      format={DateFormats[FieldTypes.MonthAndYear]}
      slotProps={{
        textField: {
          required,
          inputRef,
          error: invalid,
          helperText: error?.message,
          InputProps
        }
      }}
      onChange={onChange}
      sx={{ width: '100%' }}
    />
  );
};

export default withController(RHFMonthYearField);
