import axios from 'utils/axios';
import { toast } from 'react-toastify';
import {
  GET_CATEGORIES,
  GET_CASHFLOW,
  GET_FINANCIAL_PLAN,
  SET_FINANCIAL_PLAN,
  DELETE_FINANCIAL_PLAN_DATA,
  GET_GOALS,
  GET_PFS_REPORT,
  GET_PORTFOLIO_CURRENT_DATA,
  GET_PLAN,
  GET_OPTIMIZE_DATA,
  GET_CASHFLOW_TABLE_DATA,
  GET_SPLASH_DATA
} from './constant';
import { Categories } from 'utils/categories';
import dayjs from 'dayjs';
import * as am5 from '@amcharts/amcharts5';

export const themeColors = {
  'US Large Cap': am5.color('#6794DC'),
  'US Mid Cap': am5.color('#6771DC'),
  'US Small Cap': am5.color('#8067DC'),
  'International Equity': am5.color('#A367DC'),
  'Real Estate': am5.color('#C767DC'),
  'Core US Fixed Income': am5.color('#DC67CE'),
  Infrastructure: am5.color('#DC67AB'),
  'US High Yield Bond': am5.color('#DC6788'),
  'Municipal Bond': am5.color('#DC6967'),
  'Hedge Funds': am5.color('#DC8C67'),
  Commodities: am5.color('#DCAF67'),
  'Private Equity': am5.color('#DCD268'),
  'Money Market Fund': am5.color('#C3DC67')
};

const downloadFile = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'pfs_report.pdf'); // You can specify the file name here
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const finalComparisonChart = (payload, userId, houseHoldId, modalClose) => async (dispatch) => {
  const url = 'financial-plan/analyse-final-portfolio';
  let data = {
    ...(houseHoldId && { household: houseHoldId }),
    ...(userId && { advisor: userId })
  };
  dispatch({
    type: GET_CASHFLOW,
    payload: null
  });
  try {
    const response = await axios.post(url, payload, {
      params: data
    });
    dispatch({
      type: GET_CASHFLOW,
      payload: response.data.data
    });
    modalClose();
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
};

export const getSplashData = (client_id, setLoader) => async (dispatch) => {
  const url = 'marketing-material/get-marketing-material-data';
  setLoader && setLoader(true);
  let data = {
    ...(client_id && { client_id: client_id })
  };
  // dispatch({
  //   type: GET_SPLASH_DATA,
  //   payload: null
  // });
  try {
    const response = await axios.get(url, {
      params: data
    });
    dispatch({
      type: GET_SPLASH_DATA,
      payload: response.data.data
    });
    setLoader && setLoader(false);
  } catch (err) {
    setLoader && setLoader(false);
    console.log(err);
  }
};
export const uploadPDFAction = (file, pdfId, modalClose, setLoader) => async (dispatch) => {
  const url = 'marketing-material/upload-pdf';
  let formData = new FormData();
  formData.append('id', pdfId);
  formData.append('pdf_url', file);
  try {
    setLoader(true);
    const response = await axios.post(url, formData);
    toast.success(response.data.message);
    modalClose();
    setLoader(false);
    dispatch({
      type: GET_SPLASH_DATA,
      payload: response.data.data
    });
  } catch (err) {
    console.log(err);
    setLoader(false);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
};
export const updateSplashScreen = (id, isActive, apply_globally, client_id, setLoader) => async (dispatch) => {
  setLoader(true);
  const url = 'marketing-material/update-status';
  let data = {
    bon_id: id,
    status: isActive,
    ...(client_id && { client_id }),
    apply_globally: apply_globally
  };
  try {
    const response = await axios.put(url, data);
    toast.success(response.data.message);
    dispatch({
      type: GET_SPLASH_DATA,
      payload: response.data.data
    });
    setLoader(false);
  } catch (err) {
    setLoader(false);
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
};
export const implementPortfolio = (payload, userId, houseHoldId) => async (dispatch) => {
  const url = 'financial-plan/implement-final-portfolio';
  let data = {
    ...(houseHoldId && { household: houseHoldId }),
    ...(userId && { advisor: userId })
  };
  try {
    const response = await axios.post(url, payload, {
      params: data
    });
    dispatch(getFinancialPlanCashflow({ houseHoldId, userId }));
    dispatch(getFinancialPlan(houseHoldId ?? userId, !!userId));
    toast.success(response.data.message);
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
};

export const getCashFlowTableData = (userId, houseHoldId, title, year, userIds, filters) => async (dispatch) => {
  const url = 'financial-plan/get-cash-flow-detail';
  let includeIds = [],
    excludeIds = [];

  filters &&
    Object.entries(filters).forEach(([k, v]) => {
      (v ? includeIds : excludeIds).push(k);
    });

  let data = {
    ...(houseHoldId && { household: houseHoldId, ...(userIds?.length > 0 && { userIds: userIds.join(',') }) }),
    ...(userId && { advisor: userId }),
    ...(title && { detail_section: title }),
    ...(year && { detail_year: year }),
    ...(includeIds?.length > 0 && { include_ids: includeIds }),
    ...(excludeIds?.length > 0 && { exclude_ids: excludeIds })
  };
  try {
    const response = await axios.get(url, {
      params: data
    });
    dispatch({
      type: GET_CASHFLOW_TABLE_DATA,
      payload: response.data.data
    });
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
};

export const optimizedChart = (payload, userId, houseHoldId) => async (dispatch) => {
  const url = 'financial-plan/analyse-optimize-portfolio';
  let data = {
    ...(houseHoldId && { household: houseHoldId }),
    ...(userId && { advisor: userId })
  };
  dispatch({
    type: GET_CASHFLOW,
    payload: null
  });
  try {
    const response = await axios.post(url, payload, {
      params: data
    });
    dispatch({
      type: GET_CASHFLOW,
      payload: response.data.data
    });
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
};

export const getGoals = (houseHoldId, setLoading) => async (dispatch) => {
  const url = 'goals/get-household-clients/' + houseHoldId;
  let goals = [];
  try {
    const response = await axios.get(url);
    goals = response.data.data?.map((user) => ({
      user_id: user.id,
      joint: user.joint,
      name: (user.first_name ?? '') + ' ' + (user.last_name ?? ''),
      date_of_birth: user?.dob,
      current_age: user?.dob ? dayjs().diff(dayjs(user?.dob), 'year') : undefined,
      retirement_age: user?.client_goal?.retirement_age,
      planning_horizon: user?.client_goal?.planning_horizon
    }));
    dispatch({
      type: GET_GOALS,
      payload: goals
    });
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
  setLoading && setLoading(false);
  return goals;
};

export const savePlan = (payload, houseHoldId, userId, setLoading, navigate) => async (dispatch) => {
  setLoading && setLoading(true);
  const url = 'financial-plan/financial-plan-investment';
  let data = {
    ...(houseHoldId && { household: houseHoldId }),
    ...(userId && { advisor: userId })
  };
  try {
    await axios.post(url, payload, {
      params: data
    });

    dispatch(getPlan({ houseHoldId, userId }));
    dispatch(getOptimizeData({ houseHoldId, userId }));
    navigate('/financial_planning?tab=2', { state: { plan: true }, replace: true });
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
  setLoading && setLoading(false);
};

export const getFinancialPlanCashflow =
  ({ houseHoldId, userIds, includeIds, excludeIds, userId }) =>
  async (dispatch) => {
    dispatch({
      type: GET_CASHFLOW,
      payload: null
    });
    const url = 'financial-plan/get-financial-plan-cashflow';

    let data = {
      ...(houseHoldId && { household: houseHoldId, ...(userIds?.length > 0 && { userIds: userIds.join(',') }) }),
      ...(includeIds?.length > 0 && { include_ids: includeIds }),
      ...(excludeIds?.length > 0 && { exclude_ids: excludeIds }),
      ...(userId && { advisor: userId })
    };
    try {
      const response = await axios.get(url, {
        params: data
      });
      dispatch({
        type: GET_CASHFLOW,
        payload: response.data.data
      });
    } catch (err) {
      if (err.response?.status !== 404) {
        console.log(err);
      }
      dispatch({
        type: GET_CASHFLOW,
        payload: err?.response?.data?.message ?? 'Something went wrong!'
      });
    }
  };

export const getPortfolioCurrentData =
  ({ houseHoldId, userId, userIds, includeIds, excludeIds }) =>
  async (dispatch) => {
    const url = 'financial-plan/get-portfolio-current-data';

    let data = {
      ...(houseHoldId && { household: houseHoldId, ...(userIds?.length > 0 && { userIds: userIds.join(',') }) }),
      ...(includeIds?.length > 0 && { include_ids: includeIds }),
      ...(excludeIds?.length > 0 && { exclude_ids: excludeIds }),
      ...(userId && { advisor: userId })
    };
    try {
      dispatch({
        type: GET_PORTFOLIO_CURRENT_DATA,
        payload: null
      });
      const response = await axios.get(url, {
        params: data
      });
      // const totalValue = response.data.data.reduce((acc, item) => acc + item.total_asset_class_amount, 0);
      const formattedData = {
        investment_amount: response.data?.data?.investment_amount,
        chartData: [
          ...(response.data?.data?.current_asset_classes
            ?.filter((item) => item.total_asset_class_amount > 0)
            .map((item) => ({
              category: item.asset_class_name,
              value: item.total_asset_class_amount,
              asset_id: item.asset_class_id,
              id: `current ${item.asset_class_name}`,
              settings: {
                fill: am5.color(item?.asset_class_name ? themeColors[item.asset_class_name] : '#B5B5B5')
              }
            })) ?? [])
        ]
      };
      dispatch({
        type: GET_PORTFOLIO_CURRENT_DATA,
        payload: formattedData
      });
    } catch (err) {
      if (err.response?.status !== 404) {
        console.log(err);
        toast.error(err?.response?.data?.message ?? 'Something went wrong!');
      }
      dispatch({
        type: GET_PORTFOLIO_CURRENT_DATA,
        payload: err?.response?.data?.message ?? 'Something went wrong!'
      });
    }
  };

export const updateFPDataStatus =
  ({ houseHoldId, userId, filters, setFilters, setLoading }) =>
  async (dispatch) => {
    setLoading(true);
    const url = 'financial-plan/update-data-status';

    let data = {
      financial_plan_data: Object.entries(filters).map(([k, v]) => {
        return { data_id: k, is_active: +v };
      })
    };

    if (!data.financial_plan_data.length) {
      setLoading(false);
      toast.error('No filters to be applied.');
      return;
    }

    try {
      const response = await axios.post(url, data, {
        params: {
          ...(houseHoldId && { household: houseHoldId }),
          ...(userId && { advisor: userId })
        }
      });
      dispatch({
        type: GET_FINANCIAL_PLAN,
        payload: {
          data: response?.data?.data,
          update: true
        }
      });
      setFilters(null);
    } catch (err) {
      if (err.response?.status !== 404) {
        console.log(err);
        toast.error(err?.response?.data?.message ?? 'Something went wrong!');
      }
    }
    setLoading(false);
  };

export const getPlan =
  ({ houseHoldId, userId, setDataLoading }) =>
  async (dispatch) => {
    const url = 'financial-plan/get-financial-plan-investment';
    let data = {
      ...(houseHoldId && { household: houseHoldId }),
      ...(userId && { advisor: userId })
    };
    try {
      setDataLoading && setDataLoading(true);
      const response = await axios.get(url, {
        params: data
      });
      dispatch({
        type: GET_PLAN,
        payload: response?.data?.data
      });
      await dispatch(getPortfolioCurrentData({ houseHoldId, userId }));
      setDataLoading && setDataLoading(false);
    } catch (err) {
      if (err?.response?.status !== 404) {
        console.log(err);
        toast.error(err?.response?.data?.message ?? 'Something went wrong!');
      }
      dispatch({
        type: GET_PORTFOLIO_CURRENT_DATA,
        payload: err?.response?.data?.message ?? 'Something went wrong!'
      });
      setDataLoading && setDataLoading(false);
    }
  };

export const getOptimizeData =
  ({ houseHoldId, userId }) =>
  async (dispatch) => {
    const url = 'financial-plan/get-portfolio-optimized-data';
    let data = {
      ...(houseHoldId && { household: houseHoldId }),
      ...(userId && { advisor: userId })
    };
    dispatch({
      type: GET_OPTIMIZE_DATA,
      payload: null
    });
    try {
      const response = await axios.get(url, {
        params: data
      });
      const formattedData = {
        tcr_longevity_real: response.data?.data?.tcr_longevity_real,
        chartData: [
          ...(response.data?.data?.optimized_asset_classes
            ?.filter((item) => item.total_asset_class_amount > 0)
            .map((item) => ({
              category: item.asset_class_name,
              value: item.total_asset_class_amount,
              asset_id: item.asset_class_id,
              id: `optimized ${item.asset_class_name}`,
              settings: {
                fill: am5.color(item.asset_class_name ? themeColors[item.asset_class_name] : '#B5B5B5')
              }
            })) ?? [])
        ]
      };
      dispatch({
        type: GET_OPTIMIZE_DATA,
        payload: formattedData
      });
    } catch (err) {
      if (err?.response?.status !== 404) {
        toast.error(err?.response?.data?.message ?? 'Something went wrong!');
      }
      dispatch({
        type: GET_OPTIMIZE_DATA,
        payload: err?.response?.data?.message ?? 'Something went wrong!'
      });
    }
  };

export const getPfsReport =
  ({ houseHoldId, userId }, report, setLoading) =>
  async (dispatch) => {
    setLoading && setLoading(true);
    if (report !== null && typeof report === 'string') {
      // downloadFile(report);
      // setLoading && setLoading(false);
      // return;
    }
    const url = 'financial-plan/generate-pfs-report';
    let data = {
      ...(houseHoldId && { household: houseHoldId }),
      ...(userId && { advisor: userId })
    };
    try {
      const response = await axios.get(url, {
        params: data
      });

      if (response.data && response.data.download_link) {
        downloadFile(response.data.download_link);

        dispatch({
          type: GET_PFS_REPORT,
          payload: response.data.download_link
        });
      } else {
        toast.error(err?.response?.data?.message ?? 'Something went wrong!');
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message ?? 'Something went wrong!');
    }

    setTimeout(() => {
      setLoading && setLoading(false);
    }, 500);
  };

export const getGoal =
  ({ user }, setLoading) =>
  async (dispatch) => {
    const url = 'goals/get-individual-goal/' + user.id;
    let goals = [];

    try {
      const response = await axios.get(url);
      goals = response.data.data
        ? [
            {
              user_id: response.data.data.user_id,
              name: (user.first_name ?? '') + ' ' + (user.last_name ?? ''),
              date_of_birth: user?.dob,
              current_age: response.data.data?.current_age ? response.data.data?.current_age : undefined,
              retirement_age: response.data.data?.retirement_age,
              planning_horizon: response.data.data?.planning_horizon
            }
          ]
        : [
            {
              user_id: user.id,
              name: (user.first_name ?? '') + ' ' + (user.last_name ?? ''),
              date_of_birth: user?.dob,
              current_age: user?.dob ? dayjs().diff(dayjs(user?.dob), 'year') : undefined
            }
          ];
      dispatch({
        type: GET_GOALS,
        payload: goals
      });
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message ?? 'Something went wrong!');
    }
    setLoading && setLoading(false);
    return goals;
  };

export const saveGoals = (payload, modalClose, houseHoldId, userId) => async (dispatch) => {
  const url = 'goals/save-goals';
  try {
    const response = await axios.post(url, {
      goals: payload.goals
    });
    dispatch({
      type: GET_GOALS,
      payload: payload.goals
    });
    dispatch(getFinancialPlanCashflow({ houseHoldId, userId }));
    modalClose();
    toast.success(response.data.message);
    return true;
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
  return;
};

export const getCategories = () => async (dispatch) => {
  const categories = Categories;
  const url = 'financial-plan/get-financial-plan-categories';
  try {
    const response = await axios.get(url);

    const financialPlanData = {};

    response.data.data?.forEach((category) => {
      if (!category.status) {
        delete categories[category.identifier];
      } else {
        if (categories[category.identifier]) {
          categories[category.identifier]._id = category.id;
        } else {
          categories[category.identifier] = { _id: category.id };
        }

        financialPlanData[category.id] = {};

        category.sub_categories?.forEach((subCategory) => {
          if (!subCategory.status) {
            delete categories[category.identifier][subCategory.identifier];
          } else {
            if (categories[category.identifier][subCategory.identifier]) {
              categories[category.identifier][subCategory.identifier]._id = subCategory.id;
              categories[category.identifier][subCategory.identifier].name = subCategory.name;
              categories[category.identifier][subCategory.identifier].defaultTitle = subCategory.section_name;
            } else {
              categories[category.identifier][subCategory.identifier] = {
                _id: subCategory.id,
                name: subCategory.name,
                defaultTitle: subCategory.section_name
              };
            }
          }
        });
      }
    });

    dispatch({
      type: GET_CATEGORIES,
      payload: categories
    });

    dispatch({
      type: SET_FINANCIAL_PLAN,
      payload: financialPlanData
    });
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
  return categories;
};

export const getFinancialPlan = (householdOrUserId, forUser) => async (dispatch) => {
  let url;
  if (!forUser) {
    url = 'financial-plan/get-financial-plan/' + householdOrUserId;
  } else {
    url = 'financial-plan/get-advisor-financial-plan/' + householdOrUserId;
  }
  try {
    const response = await axios.get(url);
    dispatch({
      type: GET_FINANCIAL_PLAN,
      payload: {
        data: response?.data?.data,
        singleUser: forUser
      }
    });
  } catch (err) {
    if (err.response?.status !== 404) {
      console.log(err);
      toast.error(err?.response?.data?.message ?? 'Something went wrong!');
    }
  }
  return;
};

export const saveFinancialPlan = (payload) => async (dispatch) => {
  const url = 'financial-plan/update-financial-plan';
  try {
    const response = await axios.post(url, payload);
    await Promise.resolve(
      dispatch({
        type: GET_FINANCIAL_PLAN,
        payload: {
          data: response?.data?.data,
          update: true
        }
      })
    );
    return response.data.data;
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? err?.response?.data?.errors[0] ?? 'Something went wrong!');
    return false;
  }
};

export const deleteFinancialPlanData = (categoryId, subCategoryId, userId, financialPlanDataId, data) => async (dispatch) => {
  const url = 'financial-plan/delete-financial-plan-data/' + financialPlanDataId;
  try {
    const response = await axios.delete(url);
    if (response.data) {
      dispatch({
        type: DELETE_FINANCIAL_PLAN_DATA,
        payload: { categoryId, subCategoryId, userId, financialPlanDataId, data }
      });
    }
  } catch (err) {
    console.log(err);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
  return;
};
