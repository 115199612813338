import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getTenant } from 'store/Authentication/action';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const tenant = useSelector((state) => state.auth.tenant);

  useEffect(() => {
    dispatch(getTenant());
  }, [dispatch]);

  if (tenant?.status === 0 && location.pathname !== '/unavailable') {
    return <Navigate to="/unavailable" />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default MinimalLayout;
