const MQAOFrequenciesOneTime = 101;

export const WhenRecurrenceIsNotOneTime = ({ recurrence }) => {
  return recurrence !== MQAOFrequenciesOneTime;
};

export const WhenPaymentFrequencyIsOneTime = ({ payment_frequency }) => {
  return payment_frequency === MQAOFrequenciesOneTime;
};

export const WhenPayoutFrequencyIsNotOneTime = ({ payout_frequency }) => {
  return payout_frequency !== MQAOFrequenciesOneTime;
};

export const WhenLinkToLifeRisksIsSelect = ({ link_to_life_risk }) => {
  return link_to_life_risk && link_to_life_risk != '';
};

export const WhenLinkToLifeRisksIsNotSelect = ({ link_to_life_risk }) => {
  return !link_to_life_risk || link_to_life_risk == '';
};
